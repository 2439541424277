import { initializeApp } from "@firebase/app";
import {
  getMessaging,
  onMessage,
  getToken,
  isSupported,
  Messaging,
} from "@firebase/messaging";
import { firebaseConfig } from "core/consts/systemConst";
import { notify } from "core/helpers/notify";

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Messaging instance
let messaging: Messaging | undefined;
const initializeMessaging = async (): Promise<void> => {
  if (await isSupported()) {
    messaging = getMessaging(firebaseApp);
  }
};

// Request permission and get FCM token
export const requestNotificationPermission = async (): Promise<
  string | undefined
> => {
  try {
    // TODO: Use custom notification component

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      if (!messaging) {
        await initializeMessaging();
      }
      const token = await getToken(messaging!, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      return token;
    } else {
      // console.error("Notification permission not granted");
    }
  } catch (error) {
    // console.error("Error requesting notification permission:", error);
  }
  return "";
};

// Foreground notification handler
export const handleForegroundNotifications = (): void => {
  if (messaging) {
    onMessage(messaging, (payload) => {
      const { title, body } = payload.notification || {};
      notify({
        title: title || "Notification",
        message: body || "You have a new message",
        type: "info",
      });
    });
  }
};

export default firebaseApp;
