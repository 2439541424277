import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { notify } from "core/helpers/notify";
import {
  getFoodItem,
  getFoodItemById,
  placeFoodStuffOrder,
} from "../api/foodStuff.api";

type StoreState = {
  isLoading: boolean;
  foodItemList: FoodItemList;
  selectedFoodItem: FoodItem | null;
  foodStuffCart: FoodStuffCart[];
  getFoodItems: (query?: FoodStuffQuery) => Promise<void>;
  getFoodItemById: (id: string) => Promise<void>;
  addToFoodCart: (item: FoodStuffCart, quantity: number) => Promise<void>;
  deleteFromFoodStuffCart: (foodItemId: string) => Promise<void>;
  placeFoodStuffOrder: (order: FoodStuffOrder) => Promise<UIResponse>;
  emptyFoodCart: () => Promise<void>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  foodItemList: {
    data: [],
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPage: 0,
  },
  selectedFoodItem: null,
  foodStuffCart: [],
};

const useFoodStuffStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        getFoodItems: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getFoodItem(query, 1, 1);

          if (res?.isSuccessful) {
            set({
              foodItemList: {
                data: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getFoodItemById: async (id) => {
          set({ isLoading: true });

          var res = await getFoodItemById(id);

          if (res?.isSuccessful) {
            set({ selectedFoodItem: res?.data?.data });
          } else {
            set({ selectedFoodItem: null });
          }

          set({ isLoading: false });
        },
        addToFoodCart: async (foodItem, quantity) => {
          const isInCart = get().foodStuffCart?.some(
            (x) => x.foodStuffId === foodItem?.foodStuffId,
          );

          set((state) => ({
            foodStuffCart: isInCart
              ? state.foodStuffCart?.map((item) =>
                  // eslint-disable-next-line eqeqeq
                  item.foodStuffId == foodItem?.foodStuffId
                    ? {
                        ...item,
                        quantity: +quantity,
                      }
                    : item,
                )
              : [
                  ...state?.foodStuffCart,
                  {
                    ...foodItem,
                    quantity: +quantity,
                  },
                ],
          }));
        },
        emptyFoodCart: async () => {
          set({
            foodStuffCart: [],
          });
        },
        placeFoodStuffOrder: async (order) => {
          set({ isLoading: true });
          var res = await placeFoodStuffOrder(order);
          var message: any = "";

          if (res?.isSuccessful) {
            if (order?.paymentOption === 0) {
              message = "Your order has been placed successfully";
            } else {
              message = res?.data?.message;
            }
          } else {
            message = res?.data?.message ?? res?.data?.error?.message;
          }

          notify({
            message: message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        deleteFromFoodStuffCart: async (foodItemId) => {
          set((state) => ({
            foodStuffCart: state?.foodStuffCart.filter(
              (item) => item.foodStuffId !== foodItemId,
            ),
          }));
        },
      }),
      {
        name: "foodStuffStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useFoodStuffStore;
