/* eslint-disable eqeqeq */
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { notify } from "core/helpers/notify";
import {
  completeGatewayTransaction,
  fundWalletWithCard,
  getOrderStatus,
  getTransferFee,
  getWalletBalance,
  getWalletDetails,
  getWalletTransactions,
  setWalletPin,
} from "../api/wallet.api";

type State = {
  isLoading: boolean;
  paymentSourcePath: string;
  walletBalance: string | number;
  cashbackBalance: string | number;
  transactionList: {
    transactions: Transaction[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  transferCharges: TransactionCharge | null;
  walletDetail: WalletDetail | null;
  transferRequest: TransferRequest | null;
  setTransferRequest: (request: TransferRequest | null) => void;
  setWalletPin: (userId: string, pin: string) => Promise<UIResponse>;
  getWalletDetail: () => Promise<void>;
  getTransactions: (query: TransactionQuery) => Promise<void>;
  fundWalletWithCard: (amount: number, pin: string) => Promise<UIResponse>;
  completeGatewayTransaction: (
    data: PaymentGatewayFeedback,
  ) => Promise<UIResponse>;
  getWalletBalance: () => Promise<void>;
  resetTransactionList: () => void;
  setSourcePath: (path: string) => void;
  getOrderStatus: (orderId: string) => Promise<UIResponse>;
  getTransferCharges: (amount: number, type: string) => Promise<void>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  paymentSourcePath: "",
  transactionList: {
    transactions: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  walletDetail: null,
  walletBalance: 0,
  cashbackBalance: 0,
  transferCharges: null,
  transferRequest: null,
};

const useWalletStore = create<State>()(
  devtools(
    persist(
      (set, get): State => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        setTransferRequest: (request) => {
          set({ transferRequest: request });
        },
        setSourcePath: (path) => {
          set({ paymentSourcePath: path });
        },
        resetTransactionList: () => {
          set({
            transactionList: {
              transactions: [],
              pageNumber: 1,
              pageSize: 15,
              totalCount: 1,
              totalPage: 1,
            },
          });
        },
        setWalletPin: async (userId, pin) => {
          set({ isLoading: true });

          var res = await setWalletPin(userId, pin);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        getWalletDetail: async () => {
          set({ isLoading: true });

          var res = await getWalletDetails();

          if (res?.isSuccessful) {
            set({ walletDetail: res?.data });
          } else {
            set({ walletDetail: null });
          }

          set({ isLoading: false });
        },
        getWalletBalance: async () => {
          set({ isLoading: true });

          var res = await getWalletBalance();

          if (res?.isSuccessful) {
            set({
              walletBalance: res?.data?.accountBalance,
              cashbackBalance: res?.data?.cashbackBalance,
            });
          } else {
            set({ walletBalance: 0 });
          }

          set({ isLoading: false });
        },
        fundWalletWithCard: async (amount, pin) => {
          set({ isLoading: true });

          var res = await fundWalletWithCard(amount, pin);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        getTransactions: async (query) => {
          set({ isLoading: true });

          var res = await getWalletTransactions(query);

          if (res?.isSuccessful) {
            const { pageNumber, pageSize, totalCount, data, totalPage } =
              res?.data?.data;

            const fetchedTransactions =
              data != null && data?.length > 0 ? data : [];

            set((state) => ({
              transactionList: {
                transactions:
                  query?.pageNumber == 1
                    ? [...fetchedTransactions]
                    : [
                        ...state.transactionList.transactions,
                        ...fetchedTransactions,
                      ],
                pageNumber,
                pageSize,
                totalCount,
                totalPage,
              },
            }));
          }

          set({ isLoading: false });
        },
        completeGatewayTransaction: async (data) => {
          set({ isLoading: true });

          const sourcePath = get().paymentSourcePath;

          var res = await completeGatewayTransaction(data, sourcePath);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        getTransferCharges: async (amount, type) => {
          set({ isLoading: true });

          var res = await getTransferFee(amount, type);

          if (res?.isSuccessful) {
            set({ transferCharges: res?.data?.data });
          }

          set({ isLoading: false });
        },
        getOrderStatus: async (orderId) => {
          set({ isLoading: true });

          const sourcePath = get().paymentSourcePath;

          var res = await getOrderStatus(orderId, sourcePath);

          set({ isLoading: false });
          return res;
        },
      }),
      {
        name: "walletStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useWalletStore;
