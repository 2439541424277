import { apiCall } from "./apiCall";

function getDeviceInfo() {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const vendor = navigator.vendor;

  // Derive phoneModel or deviceName from userAgent if available
  const phoneModel = /Android|iPhone|iPad|iPod/i.test(userAgent)
    ? userAgent.match(/Android|iPhone|iPad|iPod/i)?.[0]
    : "Unknown";

  // Generate a unique deviceID (random ID or use navigator.deviceMemory for differentiation if supported)
  const deviceID = crypto.randomUUID
    ? crypto.randomUUID()
    : Math.random().toString(36).substring(2);

  // Combine platform and vendor for a deviceName
  const deviceName = `${vendor || "Unknown Vendor"} - ${platform}`;

  return {
    phoneModel,
    deviceID,
    deviceName,
  };
}

export const signIn = (
  username: string,
  password: string,
  firebaseToken: string,
) => {
  const userDevice = getDeviceInfo();

  return apiCall({
    endpoint: "/auth/login",
    method: "POST",
    body: {
      email_Phone: username,
      password,
      appVersion: 50000,
      phoneModel: userDevice?.phoneModel,
      deviceID: userDevice?.deviceID,
      deviceName: userDevice?.deviceName,
      deviceType: 1,
      firebaseToken,
    },
  });
};

export const verifyPhoneNumber = (phoneNumber: string) => {
  return apiCall({
    endpoint: "/auth/verify_phone",
    method: "POST",
    body: { phoneNumber },
  });
};

export const validateOTP = (phoneNumber: string, token: string) => {
  return apiCall({
    endpoint: "/auth/validate_smsToken",
    param: `${phoneNumber}/${token}`,
    method: "GET",
  });
};

export const registerNewUser = (user: NewUser) => {
  var formData = new FormData();

  formData.append("FirstName", user?.firstName);
  formData.append("LastName", user?.lastName);
  formData.append("MiddleName", user?.middleName);
  formData.append("Email", user?.email);
  formData.append("Bvn", user?.bvn);
  formData.append("PhoneNumber", user?.phoneNumber);
  formData.append("PhoneModel", user?.phoneModel);
  formData.append("Password", user?.password);
  formData.append("ConfirmPassword", user?.confirmPassword);
  formData.append("DateOfBirth", user?.dateOfBirth);
  formData.append("Gender", user?.gender);
  formData.append("Location", user?.location);
  formData.append("ImagePath", "");

  formData.append("SecurityQuestion", user?.securityQuestion);
  formData.append("SecurityAnswer", user?.securityAnswer);
  formData.append("Disclaimer", `${user?.disclaimer}`);
  formData.append("ReferralCode", user?.referralCode);
  formData.append("ProfileType", `${user?.profileType}`);

  return apiCall({
    endpoint: "/auth/register/client",
    method: "POST",
    multipart: true,
    auth: false,
    body: formData,
  });
};

export const uploadUserFile = (userId: string, file: any, action: string) => {
  const formData = new FormData();

  formData.append("userId", userId);
  formData.append("file", file);

  return apiCall({
    endpoint: "/auth",
    param: action,
    method: "PATCH",
    multipart: true,
    auth: false,
    body: formData,
  });
};

export const generateResetToken = (username: string) => {
  return apiCall({
    endpoint: "/auth/forgot_password/generate_token",
    method: "POST",
    body: { username },
  });
};

export const confirmResetToken = (email: string, token: string) => {
  return apiCall({
    endpoint: "/auth/forgot_password/confirm_token",
    method: "POST",
    body: { email, token },
  });
};

export const resetPassword = (
  username: string,
  password: string,
  confirmPassword: string,
) => {
  return apiCall({
    endpoint: "/auth/forgot_password/reset_password",
    method: "POST",
    body: { username, password, confirmPassword },
  });
};

export const upgradeAccount = (data: NationalId) => {
  return apiCall({
    endpoint: "/auth/updateBvn",
    method: "POST",
    body: { ...data },
    auth: true,
  });
};
