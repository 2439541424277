import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "assets/css/app.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <div className="relative">
    <App />
  </div>,
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      //console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      //console.error("Service Worker registration failed:", error);
    });
}

serviceWorkerRegistration.register();
// TODO: Fix for PWA
