import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { getRoutes } from "core/helpers/getRoutes";
import routes from "./routes";
import walletRoutes from "./views/wallet/routes";
import orderRoutes from "./views/order/routes";
import alertRoutes from "./views/alert/routes";
import SideNav from "core/components/layouts/SideNav";
import { useEffect } from "react";
import useUserStore from "core/services/stores/useUserStore";
import settingsRoute from "./views/settings/routes";
import referralRoutes from "./views/referral/routes";
import marketplaceRoutes from "./views/marketplace/routes";
import foodStuffRoutes from "./views/foodStuff/routes";
import useIdleTimer from "core/helpers/useIdleTimer";
import useLocationStore from "core/services/stores/useLocationStore";
import useWalletStore from "core/services/stores/useWalletStore";
import useMarketplaceStore from "core/services/stores/useMarketplaceStore";
import useFoodStuffStore from "core/services/stores/useFoodStuffStore";
import useOrderState from "core/services/stores/useOrderStore";
import useReviewStore from "core/services/stores/useReviewStore";
import { notify } from "core/helpers/notify";
import transportRoutes from "./views/transport/routes";
import useTransportationStore from "core/services/stores/useTransportationStore";
import loansRoutes from "./views/loans/routes";
import useLoanStore from "core/services/stores/useLoanStore";

export default function Portal() {
  const navigate = useNavigate();
  const token = useUserStore((store) => store.token);

  const toggleNavAction = useUserStore((store) => store.toggleSideNav);
  const resetUser = useUserStore((store) => store.reset);
  const resetLocation = useLocationStore((store) => store.reset);
  const resetWallet = useWalletStore((store) => store.reset);
  const resetMarketplace = useMarketplaceStore((store) => store.reset);
  const resetFoodStuff = useFoodStuffStore((store) => store.reset);
  const resetOrder = useOrderState((store) => store.reset);
  const resetReview = useReviewStore((store) => store.reset);
  const resetTransport = useTransportationStore((store) => store.reset);
  const resetLoan = useLoanStore((store) => store.reset);

  const logOut = (isExpired: boolean = false) => {
    resetUser();
    resetMarketplace();
    resetLocation();
    resetWallet();
    resetFoodStuff();
    resetOrder();
    resetReview();
    resetTransport();
    resetLoan();
    sessionStorage.clear();

    if (isExpired) {
      notify({
        title: "SESSION TIMEOUT",
        type: "warning",
        message: "You have been logged out automatically, due to inactivity.",
      });
    }

    navigate("/login");
  };

  const handleClickOutside = (event: any) => {
    if (
      !event.target.closest(".sideNavbar") &&
      !event?.target?.closest(".toggleBar")
    ) {
      toggleNavAction(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token == null || token?.length < 1) {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useIdleTimer({ timeout: 15 * 60 * 1000, onInactive: () => logOut(true) });

  return (
    <>
      <SideNav logOut={() => logOut(false)} />

      <Routes>
        {getRoutes([
          ...marketplaceRoutes,
          ...alertRoutes,
          ...walletRoutes,
          ...orderRoutes,
          ...foodStuffRoutes,
          ...settingsRoute,
          ...referralRoutes,
          ...transportRoutes,
          ...loansRoutes,
          ...routes,
        ])}
        <Route path="/" element={<Navigate to="/portal/dashboard" replace />} />
        <Route path="*" element={<Navigate to="/portal/notfound" replace />} />
      </Routes>
    </>
  );
}
