import HeaderPlain from "core/components/layouts/HeaderPlain";
import { btn } from "core/consts/styling";
import { addMetaData } from "core/helpers/seoHelpers";
import copyIcon from "assets/img/copy.svg";
import { notify } from "core/helpers/notify";
import useWalletStore from "core/services/stores/useWalletStore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FEE_TYPES, ORDER_STATUS } from "core/consts/systemConst";
import { Info } from "./partials/Info";
import { cx, formatCurrency } from "core/helpers/generalHelpers";
import Modal from "core/components/Modal";
import cheersIcon from "assets/img/flower.png";
import closeIcon from "assets/img/close.svg";
import greenSpinnerIcon from "assets/img/greenSpinner.svg";

const BankTransferTransaction = () => {
  const navigate = useNavigate();

  const transferRequest = useWalletStore((store) => store.transferRequest);
  const setTransferRequestAction = useWalletStore(
    (store) => store.setTransferRequest,
  );
  const charges = useWalletStore((store) => store.transferCharges);
  const getTransferChargesAction = useWalletStore(
    (store) => store.getTransferCharges,
  );
  const sourcePath = useWalletStore((store) => store.paymentSourcePath);
  const getOrderStatusAction = useWalletStore((store) => store.getOrderStatus);

  const defaultResponse: UIResponse = {
    isSuccessful: false,
    message: "Payment could not be processed",
    status: 0,
    data: false,
  };

  const [showResponseModal, setShowResponseModal] = useState(false);
  const [response, setResponse]: any = useState<UIResponse>({
    ...defaultResponse,
  });

  const handleCopy = () => {
    notify({
      type: "success",
      message: "Account number copied",
    });
    navigator.clipboard.writeText(transferRequest?.accountNumber!);
  };

  const confirmTransfer = async () => {
    var res = await getOrderStatusAction(transferRequest?.orderId!);

    if (res?.isSuccessful) {
      setResponse({
        ...res,
      });
    }
    setShowResponseModal(true);
  };

  const onModalClose = () => {
    setResponse({ ...defaultResponse });
    setTransferRequestAction(null);
    navigate(sourcePath ?? "/portal");
  };

  useEffect(() => {
    if (transferRequest == null) {
      navigate(-1);
    } else {
      getTransferChargesAction(
        transferRequest?.amount!,
        FEE_TYPES.BankTransfer,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {addMetaData({
        title: "",
        description: "",
      })}

      <div className="pb-[100px]">
        <HeaderPlain
          title="Bank transfer"
          showLogo={false}
          navigateBack
          backUrl=""
          showTitleLocation
        />

        <main className="mx-auto w-11/12 md:w-4/5 lg:w-3/5">
          <div className="mb-8 mt-5 rounded-[8px] bg-[#FBF7FD] p-5 text-center">
            <p className="mb-1 font-light">
              You can transfer to the account details below, please stay on this
              page after your transfer and confirm when done.
            </p>

            <p className="text-purple">
              * This account details will expire after 30 minutes today and can
              only be used for this transaction.
            </p>
          </div>

          <section className="rounded-[8px] bg-[#f7f1fd] p-3">
            <Info
              styling="!bg-transparent"
              title="Bank"
              value={transferRequest?.bank!}
            />

            <Info
              styling="!bg-transparent"
              title="Account name"
              value={transferRequest?.accountName!}
              allowCopy
            />

            <Info
              styling="!bg-transparent"
              title="Account number"
              value={transferRequest?.accountNumber!}
              allowCopy
            />

            <Info
              styling="!bg-transparent"
              title="Amount"
              value={charges?.charge_amount!}
              isNumber
            />
          </section>

          {charges != null && (
            <p className="my-3 text-gray">
              You are been charged{" "}
              {formatCurrency(Math.ceil(charges?.transactionfee!))} for this
              transaction
            </p>
          )}

          <div className="mt-20">
            <button
              onClick={() => handleCopy()}
              className={`${btn} mb-5 !h-[48px] w-full bg-[#f7f1fd] !px-[5px] !py-[12px] font-semibold text-white`}
            >
              <img src={copyIcon} alt="copy" />
              <span className="text-[12px] text-purple">
                Copy Account number
              </span>
            </button>

            <button
              onClick={() => confirmTransfer()}
              className={`${btn} !h-[48px] w-full bg-purple !px-[5px] !py-[12px] font-semibold text-white`}
            >
              <></>
              <span className="text-[12px] text-white">
                I have made payment
              </span>
            </button>
          </div>
        </main>
      </div>

      {showResponseModal && (
        <>
          <Modal
            bodyStyle="!bg-[#fdfaff]"
            onClose={() => {
              onModalClose();
            }}
          >
            <section>
              {(() => {
                switch (
                  (
                    response?.data?.data?.orderStatus as string
                  )?.toLocaleLowerCase()
                ) {
                  case ORDER_STATUS.awaiting_payment:
                    return (
                      <>
                        <div className="mb-10 w-full text-center">
                          <img
                            loading="lazy"
                            src={greenSpinnerIcon}
                            alt="awaiting payment"
                            className="inline-block w-10 rounded-full bg-red-300"
                          />{" "}
                        </div>

                        <p className="mb-5 mt-3 text-center font-semibold">
                          Awaiting payment
                        </p>
                        <p className="mb-8 text-center text-[#5E5C5F]">
                          Kindly check your E-mail for receipt
                        </p>
                      </>
                    );
                  case ORDER_STATUS.failed:
                    return (
                      <>
                        <div className="mb-10 w-full text-center">
                          <img
                            loading="lazy"
                            src={closeIcon}
                            alt="not successful"
                            className="inline-block w-10 rounded-full bg-red-300"
                          />{" "}
                        </div>

                        <p className="mb-5 mt-3 text-center font-semibold">
                          Payment failed
                        </p>
                        <p className="mb-8 text-center text-gray">
                          No worries, try again or use the alternative payment
                          option
                        </p>
                      </>
                    );
                  case ORDER_STATUS.pending:
                    return (
                      <>
                        <div className="mb-10 w-full text-center">
                          <img
                            loading="lazy"
                            src={greenSpinnerIcon}
                            alt="cheers!!"
                            className="inline-block w-10"
                          />{" "}
                        </div>

                        <p className="mb-5 mt-3 text-center font-semibold">
                          Payment is pending
                        </p>

                        <p className="mb-8 text-center text-[#5E5C5F]">
                          Kindly check your E-mail for receipt.
                        </p>
                      </>
                    );
                  case ORDER_STATUS.successful:
                    return (
                      <>
                        <div className="mb-10 w-full text-center">
                          <img
                            loading="lazy"
                            src={cheersIcon}
                            alt="cheers!!"
                            className="inline-block w-10"
                          />{" "}
                        </div>

                        <p className="mb-5 mt-3 text-center font-semibold">
                          Payment made successfully
                        </p>

                        <p className="mb-8 text-center text-[#5E5C5F]">
                          Kindly check your E-mail for receipt.
                        </p>
                      </>
                    );
                  default:
                    return (
                      <>
                        <div className="mb-10 w-full text-center">
                          <img
                            loading="lazy"
                            src={closeIcon}
                            alt="cheers!!"
                            className="inline-block w-10 rounded-full bg-red-300"
                          />{" "}
                        </div>

                        <p className="mb-5 mt-3 text-center font-semibold">
                          Payment could not be processed
                        </p>
                        <p className="mb-8 text-center text-[#5E5C5F]">
                          Your payment could not be processed at the moment.
                        </p>
                      </>
                    );
                }
              })()}

              <button
                onClick={() => {
                  onModalClose();
                }}
                className={cx(btn, "w-full bg-purple text-white")}
              >
                Close
              </button>
            </section>
          </Modal>
        </>
      )}
    </>
  );
};

export default BankTransferTransaction;
