import React from "react";

interface Props {
  boxStyle?: string;
  selectStyle?: string;
  value?: any;
  name?: string;
  id?: string;
  options?: any;
  disabled?: boolean;
  onChange?: any;
  onBlur?: any;
  label?: string;
  error?: any;
  defaultName?: string;
  defaultValue?: string;
  isRequired?: boolean;
  multiple?: boolean;
  instruction?: string;
  margin?: string;
}

function SelectField({
  boxStyle = "",
  selectStyle = "",
  value = "",
  name = "",
  id = "",
  options = [],
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
  label = "",
  error = "",
  defaultName = "",
  defaultValue = "",
  isRequired = false,
  multiple = false,
  instruction = "",
  margin = "",
}: Props) {
  const boxClassNames = `px-4 rounded-[8px] border border-gray-light overflow-hidden"
  } ${boxStyle}`;

  const selectClassNames = `flex placeholder-shown:text-black-light h-12 w-full items-center justify-center bg-white text-sm outline-none disabled:cursor-not-allowed ${selectStyle}`;

  return (
    <div className={margin}>
      <div className={`${boxClassNames}`}>
        {label && (
          <label htmlFor={id || name} className="-mt-[10px] block text-[14px]">
            <span className="bg-white">{label}</span>
            {isRequired && <span className="text-red-500">*</span>}
          </label>
        )}

        <select
          value={value}
          className={selectClassNames}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          multiple={multiple}
        >
          {defaultName?.length > 1 && (
            <option
              className="text-black-light"
              key={defaultValue}
              value={defaultValue}
            >
              {defaultName}
            </option>
          )}
          {options &&
            options?.length > 0 &&
            options?.map((option: any) => (
              <option className="py-3" key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
        </select>
      </div>

      {instruction && (
        <span className="text-black-light mt-1 text-xs">{instruction}</span>
      )}

      {error.length > 1 ? (
        <span className="mt-1 text-xs text-red-500">{error}</span>
      ) : null}
    </div>
  );
}

export default SelectField;
